import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import {Alert, ScrollView, View, TouchableOpacity} from 'react-native';
import React, {Fragment, useCallback, useRef, useState} from 'react';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

import {BodyText} from 'src/Modules/CommonView/Text';
import {ContinueButton} from 'src/Modules/CommonView/Buttons';
import Geocoder from 'react-native-geocoding';
import ModalOmniBar from 'src/Modules/OmniBars/ModalOmniBar';
import ModalSelector from 'react-native-modal-selector';
import {TextEntry} from 'src/Modules/CommonView/Input';
import {apiSetQuestion} from 'src/Utils/apiCalls';
import {useUserId} from 'src/Hooks/reduxHooks';
import CheckBox from 'src/Modules/Native/CheckBox';
import {navigationRef} from 'src/Nav/navigationHelpers';

// Initialize the module (needs to be done only once)
// cSpell: disable next-line
Geocoder.init('AIzaSyBl9aZnUxqdmA_6o6Rr6zSDadHdPVL3ClQ'); // use a valid API key

interface TempQuestion {
  task: string;
  challenge_medium: string;
  a1?: string;
  a2?: string;
  a3?: string;
  a4?: string;
  answers?: string | Promise<void>;
}

const LocalCreateChallengeModal = () => {
  const [tempQuestion, setTempQuestion] = useState<TempQuestion>({
    task: '',
    challenge_medium: '',
    a1: '',
    a2: '',
    a3: '',
    a4: '',
    answers: '',
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const updateTempQuestion = useCallback(
    (key: string, value: string | number) => {
      const newTempQuestion: TempQuestion = {...tempQuestion, [key]: value};
      console.log({newTempQuestion});
      setTempQuestion(newTempQuestion);
    },
    [JSON.stringify(tempQuestion)],
  );

  const [isTrueFalseAnswerIsTrue, setIsTrueFalseAnswerIsTrue] =
    useState<boolean>(true);

  const userId = useUserId();
  const firstName = useTypedSelector((state) => state?.user?.info?.firstName);
  const photoUrl = useTypedSelector((state) => state?.user?.info?.photoUrl);
  const {task, challenge_medium, a1, a2, a3, a4} = tempQuestion;

  const localDataRefetch = constants.localDataRefetch;
  const selectedLocalLocationId = useTypedSelector(
    (state) => state.local.selectedLocalLocationId,
  );

  const handleSave = () => {
    if (isLoading) {
      return;
    }
    if (!challenge_medium) {
      return Alert.alert(
        'Error',
        'Please select a fun challenge type, such as photo and video challenges!',
      );
    } else if (!task) {
      return Alert.alert('Error', 'Please create a fun task or challenge!');
    } else if (
      challenge_medium == 'multiple_choice' &&
      (!a1 || !a2 || !a3 || !a4)
    ) {
      return Alert.alert('Error', 'Please select an option for each.');
    }

    setIsLoading(true);

    const newTempQuestion = {...tempQuestion};
    delete newTempQuestion.a1;
    delete newTempQuestion.a2;
    delete newTempQuestion.a3;
    delete newTempQuestion.a4;
    if (challenge_medium == 'multiple_choice') {
      newTempQuestion.answers = JSON.stringify({
        0: {option: a1, correct: true},
        1: {option: a2, correct: false},
        2: {option: a3, correct: false},
        3: {option: a4, correct: false},
      });
    } else if (challenge_medium == 'true_false') {
      newTempQuestion.answers = JSON.stringify({
        0: {option: 'True', correct: !!isTrueFalseAnswerIsTrue},
        1: {option: 'False', correct: !isTrueFalseAnswerIsTrue},
      });
    }

    const data = {
      ...newTempQuestion,
      location_id: selectedLocalLocationId,
      challenge_page: 'local',
      points: 100,
      submitted_by_user_id: userId,
      submitted_by_user_name: firstName,
      submitted_by_user_image: photoUrl,
    };

    if (!data.location_id) {
      setIsLoading(false);
      return Alert.alert('error');
    }

    console.log({data});

    newTempQuestion.answers = apiSetQuestion(data)
      .then((data) => {
        setIsLoading(false);
        if (data?.last_insert_id) {
          console.log({data});
          localDataRefetch();
          Alert.alert(
            'Success',
            'Your question has been saved. It will be reviewed by our editors soon.',
          );
          navigationRef?.goBack('Local/LocalCreateChallengeModal.tsx');
        } else {
          Alert.alert(
            'Error Submitting Location',
            'Please contact support' + (__DEV__ ? JSON.stringify(data) : ''),
          );
        }
      })
      .catch((e) => {
        console.log({e});
        Alert.alert(JSON.stringify(e));
      });
  };

  const selectorRef = useRef<ModalSelector | null>(null);

  const labels: {[key: string]: string} = {
    null: 'Select Your Challenge Type',
    photo: 'Photo Challenge',
    video: 'Video Challenge',
    multiple_choice: 'Multiple Choice Challenge',
    true_false: 'True / False Challenge',
  };
  const {width} = useWindowDimensionsSafe();
  return (
    <View style={{flex: 1, backgroundColor: 'white', alignItems: 'center'}}>
      <ScrollView keyboardShouldPersistTaps="always">
        <View
          style={{
            flex: 1,
            backgroundColor: 'white',
            alignItems: 'center',
            width,
          }}>
          <BodyText text={'Select Your Challenge Type'} />
          <ModalSelector
            data={Object.keys(labels || {})?.map?.((label, key) => ({
              value: label?.toLowerCase?.().replace(' ', ''),
              label: labels[label] || 'Select Your Challenge Type',
              section: !label,
              key,
            }))}
            ref={selectorRef}
            onChange={({value}) => {
              console.log({value});

              updateTempQuestion('challenge_medium', value);
            }}
            cancelText={'Cancel'}
            customSelector={<View />}
            overlayStyle={{justifyContent: 'flex-end'}}
          />
          <ContinueButton
            handlePress={() => {
              selectorRef?.current?.open();
            }}
            text={labels[challenge_medium] || 'Select Your Challenge Type'}
            containerStyle={{
              width: width - 40,
            }}
            background={constants?.color?.white1}
            textStyle={{
              color: constants?.color?.gray3,
              fontFamily: constants?.font.circular,
              marginVertical: 5,
              textAlign: 'center',
              width: 220,
            }}
          />
          <BodyText text={'Question Text'} />
          <TextEntry
            placeholder="Ask a question that can only be asked at this location, by looking around the location."
            value={task}
            handleChange={(text: string) => updateTempQuestion('task', text)}
            multiline
            inputStyle={{
              width: width - 40,
              height: 100,
              borderRadius: 10,
              marginTop: 10,
            }}
          />
          {challenge_medium == 'true_false' && (
            <Fragment>
              <TouchableOpacity
                style={{
                  alignItems: 'center',

                  flexDirection: 'row',
                  marginHorizontal: 30,
                  width: '100%',
                  display: 'flex',
                }}
                onPress={() => {
                  setIsTrueFalseAnswerIsTrue(!isTrueFalseAnswerIsTrue);
                }}>
                <CheckBox
                  value={isTrueFalseAnswerIsTrue}
                  onValueChange={() =>
                    setIsTrueFalseAnswerIsTrue(!isTrueFalseAnswerIsTrue)
                  }
                  style={{width: 15, height: 15}}
                />
                <BodyText
                  text={`The answer is ${
                    isTrueFalseAnswerIsTrue ? 'true' : 'false'
                  }`}
                  textStyle={{
                    marginBottom: 20,
                    marginTop: 15,
                    fontWeight: '700',
                  }}
                />
              </TouchableOpacity>
            </Fragment>
          )}
          {challenge_medium == 'multiple_choice' && (
            <Fragment>
              <BodyText text={'Correct Answer'} />
              <TextEntry
                placeholder="Correct Answer"
                value={a1}
                handleChange={(text: string) => updateTempQuestion('a1', text)}
                // multiline
                inputStyle={{
                  width: width - 40,
                  height: 30,
                  borderRadius: 10,
                  marginTop: 10,
                }}
              />
              <BodyText text={'Wrong Answers'} />
              <TextEntry
                placeholder="Wrong Answer 1"
                value={a2}
                handleChange={(text: string) => updateTempQuestion('a2', text)}
                // multiline
                inputStyle={{
                  width: width - 40,
                  height: 30,
                  borderRadius: 10,
                  marginTop: 10,
                }}
              />
              <TextEntry
                placeholder="Wrong Answer 2"
                value={a3}
                handleChange={(text: string) => updateTempQuestion('a3', text)}
                // multiline
                inputStyle={{
                  width: width - 40,
                  height: 30,
                  borderRadius: 10,
                  marginTop: 10,
                }}
              />
              <TextEntry
                placeholder="Wrong Answer 3"
                value={a4}
                handleChange={(text: string) => updateTempQuestion('a4', text)}
                // multiline
                inputStyle={{
                  width: width - 40,
                  height: 30,
                  borderRadius: 10,
                  marginTop: 10,
                  marginBottom: 600,
                }}
              />
            </Fragment>
          )}
        </View>
      </ScrollView>
      <ModalOmniBar
        omniButton={{
          icon: require('src/Images/logo.png'),

          //icon: 'check',
          iconLibrary: 'FontAwesome',
          color: constants?.color?.orange,
          handlePress: handleSave,
          text: 'Create City Guide',
        }}
        left={{
          icon: 'arrow-left-circle',
          handlePress: () =>
            navigationRef?.goBack('Local/LocalCreateChallengeModal.tsx'),
          text: 'Cancel',
        }}
        containerStyle={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
    </View>
  );
};

export default LocalCreateChallengeModal;
