import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import {Alert, ScrollView, TouchableOpacity, View} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import MapView, {Marker} from 'src/Modules/Native/MapView';
import React, {Fragment, Ref, useCallback, useRef, useState} from 'react';

import {BodyText} from 'src/Modules/CommonView/Text';
import ExpoImage from 'src/Utils/ExpoImage';
import Geocoder from 'react-native-geocoding';
import {
  GooglePlacesAutocomplete,
  GooglePlacesAutocompleteRef,
} from 'react-native-google-places-autocomplete';
import Icon from 'react-native-vector-icons/FontAwesome';
import ModalOmniBar from 'src/Modules/OmniBars/ModalOmniBar';
import TagInput from 'src/Modules/CommonView/TagInput';
import {TextEntry} from 'src/Modules/CommonView/Input';

import {ContinueButton} from 'src/Modules/CommonView/Buttons';

import {
  apiCheckAnyChallenge,
  apiMarkDailyChallengeCompleted,
  apiSetLocation,
} from 'src/Utils/apiCalls';
import {
  setShowCreateChallengeModal,
  updateSelectedLocalLocationId,
} from 'src/Redux/reducers/local.reducer';

import {useUserId} from 'src/Hooks/reduxHooks';
import StarRating from 'src/Modules/Native/StarRating';
import {markDailyChallengeCompleted} from 'src/Redux/reducers/dailyChallenges.reducer';

import {logCustomAnalyticsEvent} from 'src/Utils/fireUtils';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {dispatchAction} from 'src/Utils/helpers';

// Initialize the module (needs to be done only once)
// cSpell: disable-next-line
Geocoder.init('AIzaSyBl9aZnUxqdmA_6o6Rr6zSDadHdPVL3ClQ'); // use a valid API key

interface NewLocation {
  title?: string;
  guide_description?: string;
  guide_tips?: string;
  photo?: string;
  lat?: number;
  lng?: number;
  user_suggested_rating?: 1 | 2 | 3;
  tag_ids?: string[];
}

const LocalCreateLocationModal = () => {
  const {width} = useWindowDimensionsSafe();
  const userId = useUserId();

  const userLat =
    useTypedSelector((state) => state.location?.region?.latitude) || 0;
  const userLong =
    useTypedSelector((state) => state.location?.region?.longitude) || 0;

  const [tempQuestion, setTempQuestion] = useState<NewLocation>({
    user_suggested_rating: 1,
  });
  const tempQuestionRef = useRef<NewLocation>({user_suggested_rating: 1});

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const updateTempQuestion = (updateData = {}) => {
    const oldTempQuestion = {...tempQuestionRef.current};
    const newTempQuestion = {...oldTempQuestion, ...updateData};
    console.log('updateTempQuestion', {
      updateData,
      oldTempQuestion,
      newTempQuestion,
    });
    setTempQuestion(newTempQuestion);
    tempQuestionRef.current = newTempQuestion;
  };

  console.log('LocalCreateLocationModal', {tempQuestion});

  const {
    title,
    guide_description,
    guide_tips,
    photo,
    lat,
    lng,
    user_suggested_rating,
    tag_ids = [],
  } = tempQuestion;

  const starRatingInfo = {
    1: {
      title: '1 Star Site - Worth a Stop',
      body: 'Interesting sites worth seeing, but not worth going too far out of your way. If you’re in an area or passing by the site you should check these out.',
    },
    2: {
      title: '2 Star Site - Worth a Detour',
      body: 'Interesting and likely to be remembered sites where you’d recommend going slightly out of your way to see. You might drive 15 minutes out of your way to see this site. ',
    },
    3: {
      title: '3 Star Site - Worth Planning To See',
      body: 'The best of the best and are worth planning a good portion of your day to see. They might be further away or it will require more time to explore the area— either way it’s awesome. ',
    },
  };

  // checking for daily challenges that are local submits
  const dailyChallenges =
    useTypedSelector((state) => state.dailyChallenges?.challenges) || [];
  const unfinishedLocalCreateChallenge = dailyChallenges?.find((challenge) => {
    return (
      challenge.challenge_medium === 'local_submit' && !challenge?.completed
    );
  });
  // navigationRef.devAlert(unfinishedLocalCreateChallenge);

  const autocompleteRef = useRef<GooglePlacesAutocompleteRef>();
  const localDataRefetch = constants.localDataRefetch;

  // used for updating the address and lat and long after drag end, when the location is editable
  const handleUpdateAddress = useCallback(
    async ({lat, long, photo}: {lat: number; long: number; photo: string}) => {
      //console.log({ lat, long });
      const newLat = lat || 0;
      const newLong = long || 0;
      //console.log('the dragged lat long is ', newLat, newLong, newData);
      Geocoder.from(newLat, newLong)
        .then((json) => {
          const address = json.results?.[0]?.formatted_address;
          if (address) {
            console.log({address});

            updateTempQuestion({
              lat: newLat,
              lng: newLong,
              address,
              photo: photo || null,
            });

            autocompleteRef?.current?.setAddressText(address);
          }
        })
        .catch((error) => {
          Alert.alert('hi');
          console.warn(error);
        });

      //console.log('the new params are ', updateInfo);
    },
    [JSON.stringify(tempQuestion)],
  );

  const firstName = useTypedSelector((state) => state?.user?.info?.firstName);
  const userPhotoUrl = useTypedSelector((state) => state?.user?.info?.photoUrl);

  const handleSave = () => {
    if (isLoading) {
      return;
    }
    if (!title) {
      return Alert.alert('Error', 'A title is required');
    } else if (!guide_description) {
      return Alert.alert('Error', 'A guide description is required');
    } else if (!guide_tips) {
      return Alert.alert('Error', 'Guide tips are required');
    } else if (!lat) {
      return Alert.alert('Error', 'An address is required');
    }
    setIsLoading(true);

    apiSetLocation({
      ...tempQuestionRef.current,
      tag_ids: JSON.stringify(tempQuestion.tag_ids),
      submitted_by_user_id: userId,
      submitted_by_user_name: firstName,
      submitted_by_user_image: userPhotoUrl,
      rating: -2,
    })
      .then(async (data) => {
        setIsLoading(false);
        console.log({data});
        // return Alert.alert('data', JSON.stringify(data));
        if (data?.last_insert_id) {
          localDataRefetch();

          dispatchAction(
            updateSelectedLocalLocationId(data?.last_insert_id as string),
          );
          dispatchAction(setShowCreateChallengeModal(true));

          const checkParams = {
            challenge_id: '',
            location_id: data?.last_insert_id,
            photo_url: '',
            challenge_type: 'local_add_location',
            points_earned: 500,
            challenge_page: 'local',
          };
          console.log({checkParams});
          const checkAnyChallengeResponse = await apiCheckAnyChallenge(
            checkParams,
          );

          console.log({checkAnyChallengeResponse});

          // checking if this is finishing daily challenge requirements
          if (unfinishedLocalCreateChallenge) {
            await apiMarkDailyChallengeCompleted({
              userId: userId || '',
              scheduled_id: unfinishedLocalCreateChallenge.scheduled_id,
              photo_url: '',
              shareUUID: '',
            });
            localDataRefetch();

            dispatchAction(
              markDailyChallengeCompleted({
                challenge: unfinishedLocalCreateChallenge,
                photo_url: '',
                shareUUID: '',
              }),
            ),
              logCustomAnalyticsEvent('local_spot_view', {
                ...location,
                userId,
              });

            return navigationRef.navigate(
              'LocalSiteModal',
              null,
              'Local/LocalCreateLocationModal.tsx',
            );
          } else {
            navigationRef.navigate(
              'LocalSiteModal',
              null,
              'Local/LocalCreateLocationModal.tsx',
            );
          }
        } else {
          Alert.alert('Error Submitting Location', 'Please contact support');
        }
      })
      .catch((e) => {
        console.log({e});
        Alert.alert(JSON.stringify(e));
      });
  };

  return (
    <View
      style={{flex: 1, backgroundColor: 'white', alignItems: 'center'}}
      key="keepRef">
      <ScrollView keyboardShouldPersistTaps="always">
        <View
          style={{
            flex: 1,
            backgroundColor: 'white',
            alignItems: 'center',
            width,
            paddingBottom: 200,
          }}>
          <BodyText text={'Site Name'} />
          <TextEntry
            placeholder="Choose something descriptive."
            value={title}
            handleChange={(text) => updateTempQuestion({title: text})}
            // multiline
            inputStyle={{
              width: width - 40,
              height: 38,
              borderRadius: 10,
              marginTop: 10,
              textAlign: 'center',
            }}
          />
          <BodyText text={'Site Photo'} textStyle={{marginTop: 20}} />
          <TouchableOpacity
            style={{
              width: width - 40,
              height: 140,
              borderRadius: 10,
              marginTop: 10,
              backgroundColor: constants?.color?.gray3,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onPress={() => {
              navigationRef.navigate(
                'PhotoVideoCamera',
                {
                  text: 'Upload A Photo For This Location',
                  onCapture: (data) => {
                    const {downloadURL, isFromCameraRole} = data;

                    // Alert.alert(downloadURL);
                    updateTempQuestion({photo: downloadURL});

                    // taken at location
                    // so use that
                    if (!isFromCameraRole) {
                      handleUpdateAddress({
                        lat: userLat,
                        long: userLong,
                        photo: downloadURL,
                      });

                      // from camera role with exif data
                    }
                  },
                  hideShare: true,
                  showPhotoUpload: true,
                  isVideo: false,
                },
                'LocalCreateLocationModal',
              );
            }}>
            {!!photo && (
              <ExpoImage
                source={{uri: photo}}
                resizeMode={'cover'}
                style={{width: width - 40, height: 140, borderRadius: 10}}
              />
            )}
            {!photo && <Icon name="camera" size={40} color={'white'} />}
          </TouchableOpacity>
          <BodyText text={'Site Location'} />
          <View style={{height: 10, width: 10}} />

          <GooglePlacesAutocomplete
            placeholder="Enter Address"
            enablePoweredByContainer={false}
            listViewDisplayed={false}
            ref={autocompleteRef as Ref<GooglePlacesAutocompleteRef>}
            // currentLocation={true}
            styles={{
              textInputContainer: {
                width: width - 40,
              },
              // cSpell: disable-next-line
              placesautocomplete: {
                flex: 1,
              },
              listView: {
                width: width - 40,
              },
              textInput: {
                height: 38,
                color: '#5d5d5d',
                fontSize: 16,
                backgroundColor: constants?.color?.white1,
                textAlign: 'center',
              },
              predefinedPlacesDescription: {
                color: '#1faadb',
              },
            }}
            fetchDetails={true}
            onPress={(data, details = null) => {
              console.log({data, details});
              updateTempQuestion({
                address: details?.formatted_address,
                lat: details?.geometry.location.lat,
                lng: details?.geometry.location.lng,
              });
            }}
            onFail={(error) => console.error(error)}
            query={{
              // cSpell: disable-next-line
              key: 'AIzaSyBl9aZnUxqdmA_6o6Rr6zSDadHdPVL3ClQ',
              language: 'en',
            }}
          />
          {!!lat && (
            <Fragment>
              <BodyText text={'Drag Marker To Precise Location'} />
              <MapView
                region={
                  lat
                    ? {
                        latitude: Number(lat),
                        longitude: Number(lng),
                        latitudeDelta: 0.0922 / 20,
                        longitudeDelta: 0.0421 / 20,
                      }
                    : {
                        latitude: 0,
                        longitude: 0,
                        latitudeDelta: 0.0922 / 20,
                        longitudeDelta: 0.0421 / 20,
                      }
                }
                // def={15}
                style={{
                  width: width - 40,
                  height: 200,
                  borderRadius: 10,
                  marginTop: 10,
                }}>
                {!!lat && !!lng && (
                  <Marker
                    draggable
                    onDragEnd={(event) => {
                      console.log({event});

                      updateTempQuestion({
                        lat: event.nativeEvent.coordinate.latitude,
                        lng: event.nativeEvent.coordinate.longitude,
                      });
                      handleUpdateAddress({
                        lat: event.nativeEvent.coordinate.latitude,
                        long: event.nativeEvent.coordinate.longitude,
                        photo: '',
                      });
                    }}
                    coordinate={{
                      latitude: Number(lat) || 0,
                      longitude: Number(lng) || 0,
                    }}></Marker>
                )}
              </MapView>
            </Fragment>
          )}

          <BodyText text={'Description'} />
          <TextEntry
            placeholder="Entice people to visit your location (try to write at least 300 words)."
            handleChange={(text) =>
              updateTempQuestion({guide_description: text})
            }
            value={guide_description}
            multiline
            inputStyle={{
              width: width - 40,
              height: 60,
              borderRadius: 10,
              marginTop: 10,
              textAlign: 'left',
              padding: 10,
            }}
          />
          <BodyText text={'Know Before You Go'} />
          <TextEntry
            placeholder="What should people know before going?"
            handleChange={(text) => updateTempQuestion({guide_tips: text})}
            value={guide_tips}
            multiline
            inputStyle={{
              width: width - 40,
              height: 60,
              borderRadius: 10,
              marginTop: 10,
              marginBottom: 20,
              textAlign: 'left',
              padding: 10,
            }}
          />
          <BodyText text={'Tag Your Location'} />
          <TagInput
            tag_ids={tag_ids}
            handlePress={(newTagsIds) => {
              // Alert.alert(JSON.stringify(newTagsIds));
              updateTempQuestion({tag_ids: newTagsIds});
            }}
          />
          <BodyText text={'Rate your location'} />
          <StarRating
            containerStyle={{marginBottom: 10, marginTop: 20}}
            disabled={false}
            ratingCount={3}
            imageSize={60}
            rating={user_suggested_rating}
            onFinishRating={(star_rating: 1 | 2 | 3) =>
              updateTempQuestion({user_suggested_rating: star_rating})
            }
            fullStarColor={constants?.color?.yellow}
            emptyStarColor={constants?.color?.gray2}
          />
          {!!tempQuestion?.user_suggested_rating && (
            <BodyText
              text={starRatingInfo[tempQuestion?.user_suggested_rating]?.title}
              textStyle={{
                fontWeight: '900',
                fontFamily: constants?.font.circularBold,
              }}
            />
          )}
          {!!tempQuestion?.user_suggested_rating && (
            <BodyText
              text={starRatingInfo[tempQuestion?.user_suggested_rating]?.body}
              center
              textStyle={{paddingLeft: 10, paddingRight: 10}}
            />
          )}
          <ContinueButton
            containerStyle={{
              width: width - 30,
              marginTop: 50,
              marginBottom: 20,
              marginHorizontal: 15,
            }}
            text={`Submit Guide Location`}
            handlePress={handleSave}
          />
          <View style={{height: 200, width: 200}} />
        </View>
      </ScrollView>
      <ModalOmniBar
        omniButton={{
          icon: require('src/Images/logo.png'),

          //icon: 'check',
          iconLibrary: 'FontAwesome',
          color: constants?.color?.orange,
          handlePress: handleSave,
          text: 'Create City Guide',
        }}
        left={{
          icon: 'arrow-left-circle',
          handlePress: () =>
            navigationRef.navigate(
              'LandingPageMain',
              null,
              'LocalCreateLocationModal.tsx',
            ),
          text: 'Cancel',
        }}
        containerStyle={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
    </View>
  );
};

export default LocalCreateLocationModal;
