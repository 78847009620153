import React from 'react';
import {
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
  ScrollView,
  ImageBackground,
} from 'react-native';

const signInImage = require('src/Images/signInImage.png');
import ExpoImage from 'src/Utils/ExpoImage';
import constants from 'src/constants';
import {ContinueButton} from 'src/Modules/CommonView';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import {useUserId} from 'src/Hooks/reduxHooks';
import {useFirebaseData} from 'src/Utils/fireUtils';
import SubscriptionPrompt from 'src/Modules/LetsRoamPlus/SubscriptionPrompt';
import EmptyDataCard from 'src/Modules/CommonView/EmptyDataCard';
import {HeaderAndCarouselView} from 'src/Modules/CommonView/HeaderAndCarouselView';
import {updateAllUserPhotos} from 'src/Redux/reducers/user_photo.reducer';
import SiteStatsCard from 'src/Modules/CommonView/SiteStatsCards';
import {updateLocalView} from 'src/Redux/reducers/local.reducer';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {TPhoto, isTPhoto} from 'src/types/TPhoto';
import {useExplorerPass} from 'src/Utils/explorerPassHelper';
import {LeaderboardDataUpdater} from './LeaderboardPage';
import {dispatchAction} from 'src/Utils/helpers';
import Missions from 'src/Modules/Missions';
import getAppType from 'src/Utils/getAppType';

const ProfilePage = () => {
  console.log('ProfilePage Render');
  const {width} = useWindowDimensions();

  const huntsCompleted = useTypedSelector(
    (state) => state?.user?.info?.currentScoreBreakdown?.activityCount || 0,
  );

  const firstName =
    useTypedSelector((state) => state?.user?.info?.firstName) || 'Log In';
  const lastName =
    useTypedSelector((state) => state?.user?.info?.lastName) || 'Log In';

  const handleTopPress = (side: 'map' | 'list') => {
    dispatchAction(updateLocalView(side));
  };

  const userPhoto = useTypedSelector(
    (state) =>
      state.user?.info?.customPhotoUrl || state.user?.info?.photoUrl || '',
  );

  const userId = useUserId();

  const localLocationsList = useTypedSelector(
    (state) => state.local.localLocationsList,
  );

  const localFavorites = useTypedSelector(
    (state) => state.local.localFavorites,
  );

  const leaderboard = useTypedSelector((state) => state.home.leaderboard);
  let myRank = '50+';
  leaderboard?.map?.((leaderboardUser, index) => {
    if (leaderboardUser?.userId == userId) {
      myRank = (index + 1).toString();
    }
  });

  const yourSitesAndFavorites = localLocationsList?.filter((location) => {
    const isFavorited = localFavorites?.includes?.(location.id + '');
    const userDidSubmit = userId && location.submitted_by_user_id === userId;
    return isFavorited || userDidSubmit;
  });

  const explorePass = useExplorerPass();

  const membershipPrice = getAppType() == 'lr' ? '19.99' : '49.99';

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <ScrollView>
        <ImageBackground
          source={require('src/Images/island_background.png')}
          style={{
            width: width,
            paddingHorizontal: 24,
            paddingTop: 40,
            paddingBottom: 24,
            gap: 16,
          }}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 16,
            }}>
            {/* Name and DP */}
            <View>
              <ExpoImage
                source={userPhoto ? {uri: userPhoto} : signInImage}
                style={{
                  width: 86,
                  height: 86,
                  borderRadius: 86,
                  backgroundColor: 'red',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}></ExpoImage>
              {!!explorePass && (
                <View
                  style={{
                    position: 'absolute',
                    flexDirection: 'row',
                    bottom: 3,
                    borderRadius: 57,
                    justifyContent: 'center',
                    alignItems: 'center',
                    left: '50%',
                    backgroundColor: constants.color.orange,
                    paddingHorizontal: 7,
                    paddingVertical: 4,
                    gap: 2,
                  }}>
                  <ExpoImage
                    source={require('src/Images/Icons/diamond_icon.png')}
                    style={{
                      width: 16,
                      height: 16,
                    }}></ExpoImage>
                  <Text
                    style={{
                      fontSize: 13,
                      fontFamily: 'JakartaBold',
                      color: constants.color.white,
                    }}>
                    LR+
                  </Text>
                </View>
              )}
            </View>
            <Text
              style={{
                fontSize: 20,
                fontFamily: 'JakartaBold',
                color: constants.color.white,
              }}>
              {!!userId
                ? !firstName || firstName?.includes('User')
                  ? 'Your Profile'
                  : `${firstName || ''} ${lastName || ''}`
                : 'Please Log In'}
            </Text>
          </View>
          <ContinueButton
            containerStyle={{
              paddingHorizontal: 24,
              paddingVertical: 16,
              alignItems: 'center',
              gap: 8,
              marginHorizontal: 0,
              marginVertical: 0,
            }}
            height={57}
            handlePress={() =>
              navigationRef.navigate(
                !!explorePass ? 'PersonalAssistant' : 'FreeTrialScreen',
                null,
                'ProfilePage.tsx',
              )
            }
            iconImage={require('src/Images/Icons/chat_icon.png')}
            iconImageStyle={{
              height: 30,
              width: 30,
              marginLeft: 0,
              marginRight: 0,
            }}
            text={
              !!explorePass
                ? 'Ask your personal assistant'
                : 'Upgrade to unlock personal assistant'
            }
            textStyle={{fontSize: 15}}></ContinueButton>
        </ImageBackground>
        {!!userId && <ProfilePageUserPhotos userId={userId} />}
        <View
          style={{
            height: 250,
            paddingHorizontal: 24,
            gap: 16,
            paddingVertical: 24,
          }}>
          <View style={{flex: 3, flexDirection: 'row', gap: 8}}>
            <TouchableOpacity
              style={{flex: 1}}
              onPress={() =>
                navigationRef.navigate('PastHuntsModal', null, 'ProfilePage')
              }>
              <ImageBackground
                source={require('src/Images/stylized_bg.png')}
                resizeMode={'stretch'}
                imageStyle={{
                  tintColor: 'black',
                }}
                style={{
                  flex: 1,
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: 0,
                  backgroundColor: constants.color.teal,
                  borderRadius: 8,
                }}>
                <Text
                  style={{
                    fontFamily: 'JakartaBold',
                    fontSize: 32,
                    color: constants.color.white,
                  }}>
                  {huntsCompleted}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Jakarta',
                    fontSize: 12,
                    color: constants.color.white,
                  }}>
                  Hunts completed
                </Text>
              </ImageBackground>
            </TouchableOpacity>
            <TouchableOpacity
              style={{flex: 1}}
              onPress={
                () => console.log('navigate to app leaderboard')
                // navigationRef.navigate('Leaderboard', null, 'ProfilePage')
              }>
              <ImageBackground
                source={require('src/Images/stylized_bg.png')}
                resizeMode={'stretch'}
                imageStyle={{
                  tintColor: 'black',
                }}
                style={{
                  flex: 1,
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: 0,
                  backgroundColor: constants.color.teal,
                  borderRadius: 8,
                }}>
                <Text
                  style={{
                    fontFamily: 'JakartaBold',
                    fontSize: 32,
                    color: constants.color.white,
                  }}>
                  {myRank}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Jakarta',
                    fontSize: 12,
                    color: constants.color.white,
                  }}>
                  Leaderboard position
                </Text>
              </ImageBackground>
            </TouchableOpacity>
          </View>
          <SiteStatsCard />
        </View>
        {!explorePass ? (
          <SubscriptionPrompt
            title={'Explore smarter '}
            titleBold={'with Let’s Roam+'}
            description={`Discover why thousands of travelers choose Let’s Roam+ to help them research, plan, and travel smarter. Start your free trial today, then $${membershipPrice}/month.`}
          />
        ) : (
          <View>
            {/* <View
              style={{
                marginTop: 20,
                paddingHorizontal: 24,
              }}>
              <View
                style={{
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Text
                  style={{
                    fontSize: 17,
                    fontFamily: 'JakartaBold',
                  }}>
                  Your Wanderlists
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'JakartaBold',
                    color: constants.color.orange,
                  }}>
                  See all (2)
                </Text>
              </View>
            </View> */}
            {/* <EmptyDataCard
              title="You haven’t created a Wanderlist yet."
              icon={require('src/Images/Icons/explore.png')}
              description="Create your first itinerary and share it with the LR+ community!"
            /> */}
            <View
              style={{
                marginTop: 20,
                paddingHorizontal: 24,
              }}>
              <View
                style={{
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Text
                  style={{
                    fontSize: 17,
                    fontFamily: 'JakartaBold',
                  }}>
                  Missions
                </Text>

                <TouchableOpacity
                  onPress={() => {
                    navigationRef.navigate('Missions', null, 'ProfilePage.tsx');
                  }}>
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: 'JakartaBold',
                      color: constants.color.orange,
                    }}>
                    See all
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
            <Missions hideHeading={true} limit={3} removeBottomPadding={true} />
            <Text
              style={{
                marginTop: 20,
                paddingHorizontal: 24,
                fontFamily: 'Jakarta',
                fontSize: 28,
              }}>
              Favorites
            </Text>
            <View
              style={{
                marginTop: 20,
                paddingHorizontal: 24,
              }}>
              <View
                style={{
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Text
                  style={{
                    fontSize: 17,
                    fontFamily: 'JakartaBold',
                  }}>
                  Locations
                </Text>
                <TouchableOpacity
                  onPress={() => {
                    if (!yourSitesAndFavorites.length) {
                      return;
                    }
                    handleTopPress('map');
                    navigationRef.navigate('LocalTab', null, 'ProfilePage.tsx');
                    // setHasClosedBestieTest(1);
                  }}>
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: 'JakartaBold',
                      color: constants.color.orange,
                    }}>
                    {`See all (${yourSitesAndFavorites.length || 0})`}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
            {!!yourSitesAndFavorites?.[0] ? (
              <HeaderAndCarouselView
                type={'local_card'}
                initialMaxLength={5}
                carouselIndex={4}
                cardPress={() => null}
                data={yourSitesAndFavorites}
              />
            ) : (
              <EmptyDataCard
                title="You haven’t saved a City Guide stop yet."
                icon={require('src/Images/Icons/mapMarkerWhiteOutline.png')}
                description="Explore the City Guide and find incredible places all around you!"
              />
            )}
            {/* <View
              style={{
                marginTop: 20,
                paddingHorizontal: 24,
              }}>
              <View
                style={{
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Text
                  style={{
                    fontSize: 17,
                    fontFamily: 'JakartaBold',
                  }}>
                  Wanderlists
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'JakartaBold',
                    color: constants.color.orange,
                  }}>
                  See all (2)
                </Text>
              </View>
            </View> */}
            {/* <EmptyDataCard
              title="You haven’t favorited a Wanderlist yet."
              icon={require('src/Images/Icons/explore.png')}
              description="Discover curated itineraries created by LR+ members like you!"
            /> */}
            {/* <View
              style={{
                marginTop: 20,
                paddingHorizontal: 24,
              }}>
              <View
                style={{
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Text
                  style={{
                    fontSize: 17,
                    fontFamily: 'JakartaBold',
                  }}>
                  Local Conversations
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'JakartaBold',
                    color: constants.color.orange,
                  }}>
                  See all (2)
                </Text>
              </View>
            </View>
            <View style={{marginBottom: 20}}>
              <EmptyDataCard
                title="You haven’t favorited a Local Conversation yet."
                icon={require('src/Images/Icons/chatBoxWhite.png')}
                description="Participate in locally-focused discussions in your area!"
              />
            </View> */}
          </View>
        )}
      </ScrollView>
      {/* Hook to update leader board data aka rank on this page */}
      <LeaderboardDataUpdater />
    </SafeAreaView>
  );
};

const ProfilePageUserPhotos = ({userId}: {userId: string}) => {
  const allUserPhotos = useTypedSelector((state) => state.photos.allUserPhotos);
  useFirebaseData(
    `extendedUserData/${userId}/all_user_photos`,
    (photos: unknown) => {
      if (photos) {
        try {
          const photosArr = Array.isArray(photos)
            ? photos
            : JSON.parse(String(photos));

          if (Array.isArray(photosArr)) {
            const validatedPhotos: TPhoto[] = [];

            for (const photo of photosArr) {
              if (isTPhoto(photo, true)) {
                // using type guard
                validatedPhotos.push(photo);
              }
            }

            dispatchAction(updateAllUserPhotos(validatedPhotos));
          }
        } catch (e) {
          console.error('An error occurred:', e);
        }
      }
    },
    '', // no order by
    null, // no order by
    false, // hide logs
    !userId, // suppress if not userId
  );

  return (
    <View
      style={{
        marginTop: 20,
        paddingHorizontal: 24,
        maxWidth: '100%',
      }}>
      <View
        style={{
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <Text
          style={{
            fontSize: 17,
            fontFamily: 'JakartaBold',
          }}>
          Your photos and videos
        </Text>
        <TouchableOpacity
          onPress={() => [
            navigationRef.navigate(
              'PhotosModal',
              {
                photoData: allUserPhotos,
              },
              'ProfilePage',
            ),
          ]}>
          <Text
            style={{
              fontSize: 12,
              fontFamily: 'JakartaBold',
              color: constants.color.orange,
            }}>
            {`See all (${allUserPhotos.length || 0})`}
          </Text>
        </TouchableOpacity>
      </View>
      {!!allUserPhotos && (
        <HeaderAndCarouselView
          type={'image_card'}
          initialMaxLength={5}
          carouselIndex={4}
          cardPress={() => null}
          data={allUserPhotos}
        />
      )}
    </View>
  );
};

export default ProfilePage;
